<form
  (ngSubmit)="calculatePremiums()"
  [formGroup]="form">

  @if (!showProducts) {
    <!--    <div class="row">
          <div class="col-12 mb-3">
            <app-question-box
              (answerSelect)="setValueToForm(form.controls.isEbike, $event.value)"
              [answers]="bikeAnswerOptions"
              [title]="'Was für ein Fahrrad möchten Sie versichern?'"></app-question-box>
          </div>
        </div>-->
    @if (form.controls.isEbike.value !== null) {
      <div class="row">
        <div class="col-12 col-md-9 col-lg-6 ms-auto me-auto">
          <p class="servo-text-secondary fw-medium pb-0 text-center">
            Bitte geben Sie uns den Kaufpreis Ihres Fahrrads inkl. Zubehör bekannt, damit wir Ihnen eine optimale
            Versicherung anbieten können.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-9 col-lg-4 mb-5 ms-auto me-auto">
          <div class="mt-3">
            <label
              for="price"
              class="form-label">Kaufpreis (inkl. Zubehör)</label>

            <input
              [formControl]="form.controls.price"
              type="number"
              class="form-control form-control-sm"
              id="price">

            @if (form.controls.price.hasError('max')) {
              <p class="error-message">
                Ihr Fahrrad darf maximal 15.000 € kosten! <br /> Sollten Sie ein Fahrrad mit einem höheren Kaufpreis
                versichern wolle, kontaktieren Sie uns bitte unter <a href="mailto:office@myservo.at">office&#64;myservo.at</a>.
              </p>
            }
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-9 col-lg-4 ms-auto me-auto mb-3">
          <button
            type="submit"
            class="btn btn-primary w-100">

            Prämie berechnen
          </button>
        </div>
      </div>
    }
  }

  @if (showProducts) {
    <div
      class="row"
      id="product-row">

      <div class="col-md-4">
        <div
          class="back-button"
          (keyup.enter)="showProducts = false"
          (click)="showProducts = false"
          role="presentation">

          <i class="bi bi-arrow-left"></i> Zurück zur Eingabe
        </div>
      </div>
    </div>

    <div class="row">
      @if (premiumForDiebstahl !== null) {
        <div
          class="col-md-4 order-2 order-md-0 d-flex align-items-center"
          [@slideUp]="{ value: '', params: { delay:  '600ms' }}">

          <app-bike-product-card
            [title]="'Diebstahldeckung'"
            [premium]="premiumForDiebstahl"
            [isRecommended]="false"
            (submitted)="submit(BikeProductTypeEnum.DIEBSTAHL)">

            <div class="feature-point">
              – <strong>Selbstbehalt</strong> im Schadenfall <br /> <strong>nur EUR 200.00</strong>
            </div>

            <div class="feature-point">
              – <strong>Reduktion</strong> des Selbstbehalts um <br /> <strong>EUR 100.00</strong>, wenn das Fahrrad beim
              Händler bezogen wird
            </div>
          </app-bike-product-card>
        </div>
      }

      @if (premiumForPremium !== null) {
        <div
          class="col-md-4 order-0 order-md-1 d-flex align-items-center"
          [@slideUp]="{ value: '', params: { delay:  '0ms' }}">

          <app-bike-product-card
            [title]="'Premiumpaket'"
            [premium]="premiumForPremium"
            [isRecommended]="true"
            (submitted)="submit(BikeProductTypeEnum.PREMIUM_PAKET)">

            <div class="feature-point">
              - <strong>Deckungsumfang:</strong> Unfall, Diebstahl, Entwendung, Beschädigung, Feuer
              & Elementarschäden, Akkutausch, Heim- & Weiterreise
            </div>

            <div class="feature-point">
              – <strong>Verschleißteile</strong> bis EUR 500.00 <strong>abgesichert</strong>
            </div>

            <div class="feature-point">
              - <strong>Deckung</strong> bei <strong>Beschädigung oder Diebstahl</strong>
              des Zubehörs inkl. Ausrüstung (auch Kleidung) bis EUR 500.00
            </div>

            <div class="feature-point">
              – <strong>Verlängerung der Garantie</strong> auf 3 Jahre
            </div>

            <div class="feature-point">
              – <strong>kein Selbstbehalt</strong> wenn eine Reparatur beim Händler erfolgt oder bei Diebstahl
            </div>

            <div class="feature-point">
              – <strong>Abgesichert</strong> bei <strong>Beschädigungen oder Diebstahl</strong> des Zubehör bis EUR 500.00
            </div>
          </app-bike-product-card>
        </div>
      }

      <div
        class="col-md-4 order-1 order-md-2 d-flex align-items-center"
        [@slideUp]="{ value: '', params: { delay:  '300ms' }}">

        @if (premiumForKompakt !== null) {
          <app-bike-product-card
            [title]="'Kompaktpaket'"
            [premium]="premiumForKompakt"
            [isRecommended]="false"
            (submitted)="submit(BikeProductTypeEnum.KOMPAKT_PAKET)">

            <div class="feature-point">
              - <strong>Deckungsumfang:</strong> Unfall, Diebstahl, Entwendung, Beschädigung, Feuer
              & Elementarschäden, Akkutausch, Heim- & Weiterreise
            </div>

            <div class="feature-point">
              – <strong>Verschleißteile</strong> bis EUR 250.00 <strong>abgesichert</strong>
            </div>

            <div class="feature-point">
              - Deckung bei <strong>Beschädigung oder Diebstahl</strong> des,
              <strong>fest mit dem Bike verbundenen Zubehörs</strong> bis EUR 250.00
            </div>

            <div class="feature-point">
              – <strong>kein Selbstbehalt</strong> wenn eine Reparatur beim Händler erfolgt oder bei Diebstahl <br />
              <small>(ansonsten Selbstbehalt bei Diebstahl <br /> EUR 100.00 und im Schadenfall EUR 50.00)</small>
            </div>
          </app-bike-product-card>
        }
      </div>
    </div>
  }
</form>
