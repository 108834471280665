import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { InsuranceTypeEnum } from '@base/models/insurance-type.enum';

@Component({
  selector: 'app-question-box',
  standalone: true,
  imports: [NgClass],
  templateUrl: './answer-box.component.html',
  styleUrl: './answer-box.component.scss'
})
export class AnswerBoxComponent<T> implements OnInit {

  @Input() title!: string;
  @Input() answers!: AnswerOption<T>[];
  @Input() selectedValue!: null | T;

  @Output() answerSelect = new EventEmitter<AnswerOption<T>>();

  selectedAnswer: AnswerOption<T> | null = null;

  ngOnInit(): void {
    if (this.selectedValue !== null) {
      this.selectedAnswer = this.answers.find(x => x.value === this.selectedValue)!;
    }
  }

  setSelectedAnswer(answerOption: AnswerOption<T>): void {
    this.selectedAnswer = answerOption;
    this.answerSelect.emit(answerOption);
  }

  protected readonly InsuranceTypeEnum = InsuranceTypeEnum;
}

export interface AnswerOption<T> {
  id: string | T;
  title: string;
  value: T;
  icon?: string;
}
