@if (!noPermission) {
  <div>
    <app-page-title
      [title]="'Bike/E-Bike Versicherung abschließen'"></app-page-title>

    @if (showRechner) {
      @if (hasProductChooser) {
        <div class="col-12 col-lg-9 m-auto">
          <app-bike-calculator-with-products
            (requestOffer)="onRequestOffer($event)"
            [presetValues]="this.presetValues"></app-bike-calculator-with-products>
        </div>
      } @else {
        <div class="col-12 col-lg-6 m-auto">
          <app-bike-calculator
            (requestOffer)="onRequestOffer($event)"></app-bike-calculator>
        </div>
      }
    } @else {
      @if (!submitted) {
        <app-bike-form-wrapper

          [price]="price"
          [isEbike]="isEbike"
          [servoAdvertiserId]="servoAdvertiserId"
          [bikeProductType]="bikeProductType!"
          (submitted)="submitted = true"
          (navigateBack)="onNavigateBackFromForm($event)"></app-bike-form-wrapper>
      }
    }

    @if (submitted) {
      <div
        class="alert alert-success text-center"
        role="alert">

        Ihr Antrag wurde erfolgreich versendet! <br />
        Sie können diese Seite nun schließen.
      </div>
    }
  </div>
}
