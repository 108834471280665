import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrencyPipe, NgClass } from '@angular/common';

@Component({
  selector: 'app-bike-product-card',
  standalone: true,
  imports: [
    NgClass,
    CurrencyPipe
  ],
  templateUrl: './bike-product-card.component.html',
  styleUrl: './bike-product-card.component.scss'
})
export class BikeProductCardComponent {

  @Input() isRecommended = false;
  @Input() title!: string;
  @Input() premium!: number;

  @Output() submitted = new EventEmitter<void>();
}
