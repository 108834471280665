import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PaginatorModule } from 'primeng/paginator';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrencyPipe, JsonPipe } from '@angular/common';
import { BikePremiumCalculationService } from '../../../../../../../../src/app/features/calculator-overview/services/bike-premium-calculation.service';
import { AnswerBoxComponent, AnswerOption } from '../answer-box/answer-box.component';
import { BikeProductCardComponent } from '../bike-product-card/bike-product-card.component';
import { triggerSlideUpAnimation } from '../../../../shared/animations/fade-in.animation';
import { BikeProductTypeEnum } from '@base/models/bike/bike-product-type.enum';

@Component({
  selector: 'app-bike-calculator-with-products',
  standalone: true,
  imports: [
    PaginatorModule,
    ReactiveFormsModule,
    CurrencyPipe,
    AnswerBoxComponent,
    BikeProductCardComponent,
    JsonPipe
],
  providers: [BikePremiumCalculationService],
  templateUrl: './bike-calculator-with-products.component.html',
  styleUrl: './bike-calculator-with-products.component.scss',
  animations: [triggerSlideUpAnimation]
})
export class BikeCalculatorWithProductsComponent {

  @Input() set presetValues(params: { isEbike: boolean | null, price: number } | null) {
    if (params) {
      this.form.controls.isEbike.setValue(params.isEbike);
      this.form.controls.price.setValue(params.price);

      this.calculatePremiums();
      this.showProducts = true;
    }
  }

  @Output() requestOffer = new EventEmitter<{
    isEbike: boolean,
    price: number,
    premium: number,
    bikeProductType: BikeProductTypeEnum
  }>();

  fb = inject(FormBuilder);
  bikePremiumCalculationService = inject(BikePremiumCalculationService);

  form = this.fb.group<BikeCalculatorFormModel>({
    isEbike: new FormControl<boolean | null>(false, [Validators.required]),
    price: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(15000)])
  });

  premiumForDiebstahl: number | null = null;
  premiumForKompakt: number | null = null;
  premiumForPremium: number | null = null;

  bikeAnswerOptions: AnswerOption<boolean>[] = [
    { title: 'Fahrrad', value: false, id: 'bike' },
    { title: 'E-Bike', value: true, id: 'ebike' }
  ];

  protected readonly BikeProductTypeEnum = BikeProductTypeEnum;

  showProducts = false;

  calculatePremiums(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const price = this.form.controls.price.value!;

    this.premiumForDiebstahl = this.bikePremiumCalculationService.getBikePremiumV2(price, BikeProductTypeEnum.DIEBSTAHL, 12);
    this.premiumForKompakt = this.bikePremiumCalculationService.getBikePremiumV2(price, BikeProductTypeEnum.KOMPAKT_PAKET, 12);
    this.premiumForPremium = this.bikePremiumCalculationService.getBikePremiumV2(price, BikeProductTypeEnum.PREMIUM_PAKET, 12);

    this.showProducts = true;
    window.setTimeout(() => {
      const row = document.getElementById('product-row')!;
      window.scrollTo({ top: row.offsetTop - 10, behavior: 'instant' });
    }, 10);
  }

  submit(bikeProductType: BikeProductTypeEnum): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const payload = {
      isEbike: this.form.controls.isEbike.value!,
      price: this.form.controls.price.value!
    };

    if (bikeProductType === BikeProductTypeEnum.DIEBSTAHL) {
      this.requestOffer.emit({
        ...payload,
        bikeProductType: BikeProductTypeEnum.DIEBSTAHL,
        premium: this.premiumForDiebstahl!
      });
    } else if (bikeProductType === BikeProductTypeEnum.KOMPAKT_PAKET) {
      this.requestOffer.emit({
        ...payload,
        bikeProductType: BikeProductTypeEnum.KOMPAKT_PAKET,
        premium: this.premiumForKompakt!
      });
    } else if (bikeProductType === BikeProductTypeEnum.PREMIUM_PAKET) {
      this.requestOffer.emit({
        ...payload,
        bikeProductType: BikeProductTypeEnum.PREMIUM_PAKET,
        premium: this.premiumForPremium!
      });
    }
  }

}

export interface BikeCalculatorFormModel {
  isEbike: FormControl<null | boolean>;
  price: FormControl<null | number>;
}
