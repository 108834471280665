<form
  class="servo-card"
  [formGroup]="form"
  (ngSubmit)="submit()">

  <div class="card-header">
    <div class="title">
      <h5 class="mb-0 d-flex">
        Berechnen Sie Ihre Prämie
      </h5>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="mb-2">
        <div class="col-12">
          <span class="fw-medium">Welche Art von Fahrrad möchten Sie versichern?</span>
        </div>

        <div class="col-12 mt-2">
          <div class="form-check form-check-inline">
            <input
              [formControl]="form.controls.isEbike"
              [value]="false"
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1">

            <label
              class="form-check-label"
              for="inlineRadio1">Fahrrad</label>
          </div>

          <div class="form-check form-check-inline">
            <input
              [formControl]="form.controls.isEbike"
              [value]="true"
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2">

            <label
              class="form-check-label"
              for="inlineRadio2">E-Bike</label>
          </div>
        </div>
      </div>

      @if (form.controls.isEbike.value !== null) {
        <div class="col-12 mb-3">
          <div class="mt-3">
            <label
              for="price"
              class="form-label fw-medium">Kaufpreis (inkl. Zubehör)</label>

            <input
              [formControl]="form.controls.price"
              type="number"
              class="form-control form-control-sm"
              id="price">
          </div>
        </div>

        @if (premium !== null) {
          <div class="col-12 mb-3">
            <div class="premium-wrapper">
              <div class="premium">
                {{ premium | currency:'EUR':'code':'1.2-2':'de-AT' }}
              </div>

              <small class="text-muted">
                (Jährliche Bruttoprämie)
              </small>
            </div>
          </div>

          <div class="col-12 mb-2">
            <button
              type="submit"
              class="btn btn-primary w-100">

              Jetzt abschließen
            </button>
          </div>
        }
      }
    </div>
  </div>
</form>
