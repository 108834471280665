<ng-container [formGroup]="formGroup">
  <h4 class="mb-3">Zu versicherndes Produkt</h4>

  <div class="row g-3">
    <div class="col-sm-4">
      <label
        for="isEbike"
        class="form-label">Art des Fahrrads</label>

      <select
        [formControl]="formGroup.controls.isEbike"
        class="form-select"
        id="isEbike">

        <option [ngValue]="false">Fahrrad</option>
        <option [ngValue]="true">E-Bike</option>
      </select>
    </div>

    <div class="col-sm-4">
      <label
        for="brand"
        class="form-label">Marke</label>

      <input
        [formControl]="formGroup.controls.brand"
        type="text"
        class="form-control"
        id="brand" />
    </div>

    <div class="col-sm-4">
      <label
        for="model"
        class="form-label">Modell</label>

      <input
        [formControl]="formGroup.controls.model"
        type="text"
        class="form-control"
        id="model" />
    </div>

    <div class="col-sm-4">
      <label
        for="serialNumber"
        class="form-label">Seriennummer</label>

      <input
        [formControl]="formGroup.controls.serialNumber"
        type="text"
        class="form-control"
        id="serialNumber" />
    </div>
  </div>
</ng-container>
