import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ProductDataComponent } from '../product-data/product-data.component';
import { LegalChecksComponent } from '../legal-checks/legal-checks.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { BikeSubmitModel } from '../../models/bike-submit.model';
import { BikeApiService } from '../../services/bike-api.service';
import { InsuranceDetailsComponent } from '@base/components/insurance-details/insurance-details.component';
import { PersonDataComponent } from '@base/components/person-data/person-data.component';
import { PaymentDataComponent } from '@base/components/payment-data/payment-data.component';
import { PersonFormModel } from '@base/models/form/person-form.model';
import { AddressFormModel } from '@base/models/form/address-form.model';
import { CountryEnum } from '@base/models/country.enum';
import { PaymentFormModel } from '@base/models/form/payment-form.model';
import { PaymentPeriod, PaymentPeriodEnum } from '@base/models/payment-period.enum';
import { InsuranceType, InsuranceTypeEnum } from '@base/models/insurance-type.enum';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { BikeProductType, BikeProductTypeEnum } from '@base/models/bike/bike-product-type.enum';
import { BikePremiumCalculationService } from '../../../../../../../../src/app/features/calculator-overview/services/bike-premium-calculation.service';
import { IbanValidator } from '../../../../../../../../src/app/shared/validators/iban.validator';

@Component({
  selector: 'app-bike-form-wrapper',
  standalone: true,
  imports: [
    ProductDataComponent,
    InsuranceDetailsComponent,
    PersonDataComponent,
    PaymentDataComponent,
    LegalChecksComponent,
    LegalChecksComponent,
    FormsModule,
    ReactiveFormsModule,
    PageTitleComponent
],
  providers: [BikeApiService, BikePremiumCalculationService],
  templateUrl: './bike-form-wrapper.component.html',
  styleUrl: './bike-form-wrapper.component.scss'
})
export class BikeFormWrapperComponent implements OnInit {

  @Input() price!: number;
  @Input() isEbike: boolean | null = null;
  @Input() servoAdvertiserId: string | null = null;
  @Input() bikeProductType: BikeProductTypeEnum | null = null;

  @Output() submitted = new EventEmitter<void>();
  @Output() navigateBack = new EventEmitter<{ isEbike: boolean | null, price: number }>();

  isLoading = false;

  fb = inject(FormBuilder);
  form = this.fb.group<BikeFormModel>({
    person: this.fb.group<PersonFormModel>({
      firstname: new FormControl<string | null>(null, [Validators.required]),
      lastname: new FormControl<string | null>(null, [Validators.required]),
      email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
      phone: new FormControl<string | null>(null),
      address: this.fb.group<AddressFormModel>({
        houseNumber: new FormControl<string | null>(null, [Validators.required]),
        street: new FormControl<string | null>(null, [Validators.required]),
        city: new FormControl<string | null>(null, [Validators.required]),
        zip: new FormControl<string | null>(null, [Validators.required]),
        country: new FormControl<CountryEnum | null>(CountryEnum.AUSTRIA, [Validators.required])
      })
    }),
    product: this.fb.group<BikeProductFormModel>({
      bikeProductType: new FormControl<BikeProductTypeEnum | null>(null, [Validators.required]),
      isEbike: new FormControl<boolean | null>(null, [Validators.required]),
      brand: new FormControl<string | null>(null, [Validators.required]),
      model: new FormControl<string | null>(null, [Validators.required]),
      serialNumber: new FormControl<string | null>(null, [Validators.required])
    }),
    payment: this.fb.group<PaymentFormModel>({
      iban: new FormControl<string | null>(null, [Validators.required, IbanValidator.checksum()]),
      paymentPeriod: new FormControl<PaymentPeriodEnum | null>(PaymentPeriodEnum.MONTHLY, [Validators.required])
    }),
    legalCheck: this.fb.group<LegalCheckFormModel>({
      communication: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      gdpr: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      commonConditions: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      informationLetter: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      sepaAllowed: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      rightOfWithdrawal: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      wishesAndNeedsFulfilled: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue]
      }),
      noLiabilityInsurance: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue]
      })
    })
  });

  isRedirectFromAdminPanel = window.location.href.includes('premium') && window.location.href.includes('price');

  constructor(
    private bikeApiService: BikeApiService,
    private bikePremiumCalculationService: BikePremiumCalculationService
  ) {
  }

  ngOnInit() {
    //If we ge redirected from the admin page we dont have a BikeProductTypeEnum
    if (this.bikeProductType) {
      this.form.controls.product.controls.bikeProductType.setValue(this.bikeProductType);
    } else {
      this.form.controls.product.controls.bikeProductType.setValue(BikeProductTypeEnum.STANDARD_BIKE_EBIKE);
    }

    this.form.controls.product.controls.isEbike.setValue(this.isEbike);
    this.checkForDataInUrl();
  }

  checkForDataInUrl(): void {
    const url = new URL(window.location.href);

    const personForm = this.form.controls.person.controls;

    if (!personForm.firstname.value && url.searchParams.has('firstname')) {
      personForm.firstname.setValue(url.searchParams.get('firstname'));
    }

    if (!personForm.lastname.value && url.searchParams.has('lastname')) {
      personForm.lastname.setValue(url.searchParams.get('lastname'));
    }

    if (!personForm.email.value && url.searchParams.has('email')) {
      personForm.email.setValue(url.searchParams.get('email'));
    }

    if (!personForm.phone.value && url.searchParams.has('phone')) {
      personForm.phone.setValue(url.searchParams.get('phone'));
    }

    if (!personForm.address.controls.street.value && url.searchParams.has('street')) {
      personForm.address.controls.street.setValue(url.searchParams.get('street'));
    }

    if (!personForm.address.controls.houseNumber.value && url.searchParams.has('houseNumber')) {
      personForm.address.controls.houseNumber.setValue(url.searchParams.get('houseNumber'));
    }

    if (!personForm.address.controls.city.value && url.searchParams.has('city')) {
      personForm.address.controls.city.setValue(url.searchParams.get('city'));
    }

    if (!personForm.address.controls.zip.value && url.searchParams.has('zip')) {
      personForm.address.controls.zip.setValue(url.searchParams.get('zip'));
    }

    const paymentForm = this.form.controls.payment.controls;

    if (!paymentForm.iban.value && url.searchParams.has('iban')) {
      paymentForm.iban.setValue(url.searchParams.get('iban'));
    }
  }

  getInsuranceDetailsTitle(): string {
    const appendix = this.bikeProductType ? ` mit ${BikeProductType.getTypeTranslation(this.bikeProductType)}` : '';
    return `${InsuranceType.getTypeTranslation(InsuranceTypeEnum.BIKE)}${appendix}`;
  }

  getPremium(): number {
    const divider = this.getPaymentDivider();

    if (this.bikeProductType) {
      return this.bikePremiumCalculationService.getBikePremiumV2(
        this.price,
        this.bikeProductType,
        divider
      )!;
    }

    if (this.form.controls.product.controls.isEbike.value) {
      return this.bikePremiumCalculationService.getEbikePremium(this.price)! / divider;
    }

    return this.bikePremiumCalculationService.getBikePremium(this.price)! / divider;
  }

  getPaymentDivider(): number {
    switch (this.form.controls.payment.controls.paymentPeriod.value) {
      case PaymentPeriodEnum.YEARLY:
        return 1;

      case PaymentPeriodEnum.HALFYEAR:
        return 2;

      case PaymentPeriodEnum.QUARTER:
        return 4;

      case PaymentPeriodEnum.MONTHLY:
        return 12;

      default:
        return 1;
    }
  }

  getPremiumIntervalText(): string {
    let value = this.form.controls.payment.controls.paymentPeriod.value;

    if (value === null) {
      value = PaymentPeriodEnum.MONTHLY;
    }

    return PaymentPeriod.getTypeTranslation(value);
  }

  getIpidLink(): string {
    if (this.bikeProductType === BikeProductTypeEnum.DIEBSTAHL) {
      return '/assets/IPID Bike Diebstahl.pdf';
    } else if (this.bikeProductType === BikeProductTypeEnum.KOMPAKT_PAKET) {
      return '/assets/IPID Bike Kompakt.pdf';
    } else if (this.bikeProductType === BikeProductTypeEnum.PREMIUM_PAKET) {
      return '/assets/IPID Bike Premiumpaket.pdf';
    }

    return 'https://myservo.at/wp-content/uploads/2022/04/IPID-ID-GO_Bike_ab-01.04.22_final.pdf';
  }

  getAvbLink(): string {
    if (this.bikeProductType) {
      return '/assets/AVB Bike Haendler.pdf';
    }

    return 'https://myservo.at/wp-content/uploads/2022/04/AVB_Bike_ab-01.04.2022_final.pdf';
  }

  submit(): void {
    this.isLoading = true;
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.isLoading = false;
      return;
    }

    const url = new URL(window.location.href)

    this.bikeApiService
      .submit({
        person: { ...this.form.getRawValue().person },
        legalCheck: { ...this.form.getRawValue().legalCheck },
        productDetails: { ...this.form.getRawValue().product, bikeProductType: this.bikeProductType },
        payment: { ...this.form.getRawValue().payment },
        insuranceType: InsuranceTypeEnum.BIKE,
        initialPrice: this.price,
        servoAdvertiserId: this.servoAdvertiserId,
        customerId: url.searchParams.get('customerId')
      } as NonNullable<BikeSubmitModel>)
      .subscribe({
        next: () => this.submitted.emit(),
        complete: () => this.isLoading = false
      });
  }

  protected readonly InsuranceTypeEnum = InsuranceTypeEnum;
}

export interface BikeFormModel {
  person: FormGroup<PersonFormModel>;
  product: FormGroup<BikeProductFormModel>;
  payment: FormGroup<PaymentFormModel>;
  legalCheck: FormGroup<LegalCheckFormModel>;
}

export interface BikeProductFormModel {
  bikeProductType: FormControl<BikeProductTypeEnum | null>;
  isEbike: FormControl<boolean | null>;
  brand: FormControl<string | null>;
  model: FormControl<string | null>;
  serialNumber: FormControl<string | null>;
}

export interface LegalCheckFormModel {
  communication: FormControl<boolean>;
  gdpr: FormControl<boolean>;
  commonConditions: FormControl<boolean>;
  informationLetter: FormControl<boolean>;
  sepaAllowed: FormControl<boolean>;
  rightOfWithdrawal: FormControl<boolean>;
  wishesAndNeedsFulfilled: FormControl<boolean>;
  noLiabilityInsurance: FormControl<boolean>;
}
