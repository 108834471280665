import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@base/services/api.service';
import { BikeSubmitModel } from '../models/bike-submit.model';

@Injectable()
export class BikeApiService extends ApiService {

  protected get serviceEndpoint(): string {
    return `${this.endpointBrokernet}/api/servo/bike/public`;
  }

  submit(data: BikeSubmitModel): Observable<BikeSubmitModel> {
    return this.http.post<BikeSubmitModel>(this.serviceEndpoint + `/onboarding`, data);
  }
}
