import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { PaginatorModule } from 'primeng/paginator';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { BikePremiumCalculationService } from '../../../../../../../../src/app/features/calculator-overview/services/bike-premium-calculation.service';

@Component({
  selector: 'app-bike-calculator',
  standalone: true,
  imports: [
    PaginatorModule,
    ReactiveFormsModule,
    CurrencyPipe
  ],
  providers: [BikePremiumCalculationService],
  templateUrl: './bike-calculator.component.html',
  styleUrl: './bike-calculator.component.scss'
})
export class BikeCalculatorComponent implements OnInit {

  @Output() requestOffer = new EventEmitter<{ isEbike: boolean, price: number, premium: number, bikeProductType: null }>();

  fb = inject(FormBuilder);
  bikePremiumCalculationService = inject(BikePremiumCalculationService);

  form = this.fb.group<BikeCalculatorFormModel>({
    isEbike: new FormControl<boolean | null>(null, [Validators.required]),
    price: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(15000)])
  });
  premium: number | null = null;

  ngOnInit() {
    this.form
      .valueChanges
      .subscribe(value => {
        if (value.isEbike !== null && value.price !== null) {
          this.setPremium(value.price!, value.isEbike!);
        }
      });
  }

  setPremium(price: number, isEbike: boolean): void {
    if (isEbike) {
      this.premium = this.bikePremiumCalculationService.getEbikePremium(price);
    } else {
      this.premium = this.bikePremiumCalculationService.getBikePremium(price);
    }
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.requestOffer.emit({
      price: this.form.getRawValue().price!,
      isEbike: this.form.getRawValue().isEbike!,
      premium: this.premium!,
      bikeProductType: null
    });
  }
}

export interface BikeCalculatorFormModel {
  isEbike: FormControl<null | boolean>;
  price: FormControl<null | number>;
}
