<div
  class="bike-product-card mb-3"
  [ngClass]="{'is-recommended': this.isRecommended}">

  @if (isRecommended) {
    <i class="bi bi-star-fill"></i>
  }

  <div class="header">
    {{ title }}
  </div>

  <div class="price-wrapper">
    <div class="price">
      {{ premium | currency:'EUR':'symbol':'1.2-2':'de-AT' }}
    </div>

    <div class="price-hint">(pro Monat)</div>
  </div>

  <div class="product-features">
    <ng-content></ng-content>
  </div>

  <div class="submit-button">
    <button
      type="button"
      class="btn btn-primary w-100"
      (click)="submitted.emit()">

      Jetzt beantragen
    </button>
  </div>
</div>
