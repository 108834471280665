import { animate, animation, style, transition, trigger, useAnimation } from '@angular/animations';

export const slideUpAnimation = animation([
  style({ opacity: 0, transform: 'translateY(10%)' }), // Start state
  animate('{{timing}} {{delay}} ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
]);

export const triggerSlideUpAnimation = trigger('slideUp', [
  transition(':enter', useAnimation(slideUpAnimation, {
    params: { timing: '850ms', delay: '' }
  }))
]);
