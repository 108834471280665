import { Routes } from '@angular/router';
import { BikePageComponent } from './features/insurance/pages/bike-page/bike-page.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => BikePageComponent
  },
  {
    path: 'public',
    loadComponent: () => BikePageComponent
  },
  {
    path: 'stella',
    loadComponent: () => BikePageComponent
  }
];
