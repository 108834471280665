import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LegalCheckFormModel } from '../bike-form-wrapper/bike-form-wrapper.component';

@Component({
  selector: 'app-legal-checks',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './legal-checks.component.html',
  styleUrl: './legal-checks.component.scss'
})
export class LegalChecksComponent {

  @Input() formGroup!: FormGroup<LegalCheckFormModel>;
  @Input() avbLink = 'https://myservo.at/wp-content/uploads/2022/04/AVB_Bike_ab-01.04.2022_final.pdf';
  @Input() ipidLink = 'https://myservo.at/wp-content/uploads/2022/04/IPID-ID-GO_Bike_ab-01.04.22_final.pdf';

}
