<div class="question">
  <div class="question-title">{{ title }}</div>

  <div class="answer-boxes row">
    @for (answer of answers;track $index) {
      <div
        (keyup.enter)="setSelectedAnswer(answer)"
        (click)="setSelectedAnswer(answer)"
        [ngClass]="{ 'selected': selectedAnswer?.id === answer.id}"
        role="presentation"
        class="answer-box col-auto">

        @if (answer.icon) {
          <i [class]="answer.icon"></i>
        }

        {{ answer.title }}
      </div>
    }
  </div>
</div>
